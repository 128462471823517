import Vue from 'vue'
import VueRouter from 'vue-router'

/*get access to the store*/
import store from '@/store';

Vue.use(VueRouter)

const routes = [
{
  path: '/',
  name: 'Home',
  component: () => import('@/views/home/index.vue'),
},
{
  path: '/orte',
  name: 'Orte',
  component: () => import('../views/ort/index.vue'),
},
{
  path: '/orte/:id',
  name: 'OrteDetail',
  component: () => import('../views/ort/show.vue'),
},
{
  path: '/angebote',
  name: 'Angebote',
  component: () => import('../views/angebot/index.vue'),
},
{
  path: '/angebote/:id',
  name: 'AngeboteDetail',
  component: () => import('../views/angebot/show.vue'),
},
{
  path: '/mehr',
  name: 'Mehr',
  component: () => import('../views/other/more.vue'),
},
{
  path: '/search',
  name: 'Search',
  component: () => import('../views/search/index.vue'),
  meta: { showHeader: false }
},
{
  path: '/nutzungsbedingungen',
  name: 'Nutzungsbedingungen',
  component: () => import('@/views/system/nutzungsbedingungen.vue'),
  meta: { showHeader: true }
},
{
  path: '/datenschutz',
  name: 'Datenschutz',
  component: () => import('@/views/system/show.vue'),
  meta: { showHeader: true }
},
{
  path: '/impressum',
  name: 'Impressum',
  component: () => import('@/views/system/show.vue'),
  meta: { showHeader: true }
},
{
  path: '/barrierefrei',
  name: 'Barrierefreiheitserklaerung',
  component: () => import('@/views/system/show.vue'),
  meta: { showHeader: true }
},
{
  path: '/system/:id',
  name: 'Systemview',
  component: () => import('@/views/system/show.vue'),
  meta: { showHeader: true }
},
{
  path: '/merkliste/:id',
  name: 'MerklisteDetail',
  component: () => import('../views/merkliste/show.vue'),
},
{
  path: '/merkliste/edit/:id',
  name: 'MerklisteEdit',
  component: () => import('../views/merkliste/edit.vue'),
  meta: {
    auth: true,
    showHeader: true
  }
},
{
  path: '*',
  name: '404',
  component: () => import('../views/other/404.vue'),
},
{
  path: '/login',
  name: 'Login',
  component: () => import('../views/auth/login'),
  meta: {
    auth: false,
    showHeader: true 
  }
},
{
  path: '/register',
  name: 'Signup',
  component: () => import('../views/auth/register'),
  meta: {
    auth: false,
    showHeader: true
  }
},
{
  path: '/apply-for-new-password',
  name: 'applyForNewPassword',
  component: () => import('../views/auth/applyForNewPassword'),
  meta: {
    auth: false,
    showHeader: true 
  }
},
{
  path: '/password-reset/:token',
  name: 'passwordForgotten',
  component: () => import('../views/auth/passwordForgotten'),
  meta: {
    auth: false
  }
},
{
  path: '/verify/:token',
  name: 'Verify',
  component: () => import('../views/auth/login')
},
{
  path: '/change-password',
  name: 'ChangePassword',
  component: () => import('../views/auth/changePassword')
},
{
  path: '/password-changed',
  name: 'PasswordChanged',
  component: () => import('../views/auth/passwordChanged')
},
{
  path: '/profile',
  name: 'Profile',
  component: () => import('../views/profile/view'),
  meta: {
    auth: true,
    showHeader: true 
  }
},
{
  path: '/edit-profile',
  name: 'EditProfile',
  component: () => import('../views/profile/edit.vue'),
  meta: {
    auth: true,
  }
},

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savePosition) {
    return {x: 0, y: 0};
  }
});

router.beforeEach((to, from, next) => {
 setTimeout(() => { window.scrollTo(0, 0); }, 100);

 var loginTime = store.getters.getLoginTime;
 if(loginTime!=null){
  var currentTime = Date.now();
  var timeDifferenceInSecond = Math.ceil(Math.abs(currentTime-loginTime)/1000);
  var timeDifferenceInMinutes=timeDifferenceInSecond/60;
  if(timeDifferenceInMinutes>60){
    /*logout*/
    store.dispatch('logout');
  }
  console.log(timeDifferenceInMinutes);
}


const token = store.getters['getToken'];
if (to.meta.auth == true && token === null) {
  /*go to login - only users that are logged in are allowed*/
  next('/login');
  return;
}
else if (to.meta.auth == false && token != null){
  /*go home - only not logged in users are allowed*/
  next('/');
  return;
}
next();
});


export default router
