import Vue from 'vue'
import Vuex from 'vuex'
import { login, getUser, logout } from '@/api/auth';
import { getCollectionsOfUser } from '@/api/collection';
import { collections4Lupe } from '@/api/custom';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    roles: null,
    language: navigator.language.slice(0,2) || navigator.userLanguage.language.slice(0,2),
    categories: null,
    login_time: null,
    optionId: null,
    wpSelectId: null,
    mutlipleSelectedValues: [],
    collections: null,
    userCollections: null,
  },
  mutations: {
    addMultipleValue(state, {
      id, value,
    }) {
      console.log('hit');
    },
    SET_COLLECTIONS: (state, collections) => {
      state.collections = collections;
    },
    SET_TOKEN: (state, token ) => {
      state.token = token;
      sessionStorage.setItem('token', token);
    },
    SET_USER: (state, user ) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_USER_COLLECTIONS: (state, collections) => {
      state.userCollections = collections;
    },
    SET_LOGIN_TIME: (state, time) => {
      state.login_time = time;
    },
    SET_LANGUAGE: (state, lang) => {
      state.language = lang;
    },
  },
  getters: {
    getLanguage(state) {
      return state.language;
    },
    getLoginTime(state) {
      return state.login_time;
    },
    getCollections(state){
      return state.collections;
    },
    getUserCollections(state){
     return state.userCollections;
   },
   getRoles(state) {
    if(state.user !=null && Object.prototype.hasOwnProperty.call(state.user,'roles')){
      return state.user.roles;
    }
    return null;
  },
  getUser(state) {
    return state.user;
  },
  getToken(state) {
    if(state.token === null){
        /*trying to get the token from sessionStorage*/
      var token = sessionStorage.getItem('token');
        /*set the token to the token from sessionStorage - but only if that token is not null*/
      if(token != null && token != "null"){
        state.token=token;
      }
    }
    return state.token;
  },
},
actions: {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  loadUserCollections({commit,getters}){
      /*get the current user*/
    var user = getters.getUser;

    if(user!=null){
      return new Promise((resolve, reject) => {
        getCollectionsOfUser(user)
        .then(response => {
          commit('SET_USER_COLLECTIONS', response);
          resolve(response); 
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Die Merklisten konnten nicht geladen werden.'
          });

          reject(error.response)
        });

      });
    }
  },
  loadUser({ commit }) {
    return new Promise((resolve, reject) => {
      getUser().then(response => {
        commit('SET_USER', response.data);
        resolve(response); 
      })
      .catch(error => {
        console.log(error);
        reject(error.response)
      });
    });
  },
  login ({commit},payload) {
    return new Promise((resolve, reject) => {
        /*a new login*/
      login(payload).then(response => {
          /*set the login-time*/
        commit('SET_LOGIN_TIME',Date.now());
          /*set the token*/
        commit('SET_TOKEN', response.data.token);
          /*set the user*/
        commit('SET_USER', response.data.user);
        resolve(response); 
      })
      .catch(error => {
        reject(error.response)
      });
    });
  },
  resetUserAndToken({commit}){
      /*set the token*/
    commit('SET_TOKEN', null);
      /*set the user*/
    commit('SET_USER', null);
      /*set the login-time*/
    commit('SET_LOGIN_TIME', null);
  },
  logout({commit}){
    return new Promise((resolve, reject) => {
        /*a new login*/
      logout().then(response => {
        resolve(response); 
      })
      .catch(error => {
        reject(error.response.data)
      })
      .finally(() => {
        commit('SET_TOKEN', null);
          /*set the user*/
        commit('SET_USER', null);
          /*Logout the user*/
        commit('SET_LOGIN_TIME',null);
      });
    });
  }, 
},
modules: {},
plugins: [createPersistedState()]
});
