import '@/bootstrap';
import Vue from 'vue';
import store from '@/store';


// Create axios instance
const service = window.axios.create({
  baseURL: "https://bildungsangebote-kiel.de/api/",
  timeout: 60000, // Request timeout
  withCredentials: true,
});

// Request intercepter
service.interceptors.request.use(
  config => {
    /*set the token for the auth-headers*/
    const token = store.getters['getToken'];
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.headers['X-Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
  );

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }

    return response.data;
  },
  error => {
    console.log(error);
    let message = error.message;
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }
    if (error.response.status == '404') {
      window.location.href = '/404';
    }

    var errortype=error.response.status;
    var theUser = store.state.user;
    //logout if the errortype is 401 and if the user is loggedin 
    if(theUser!=null && errortype == '401'){
      store.dispatch('logout');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
  );

export default service;
